.personListSection{
    padding: 20px 20px 20px 0px;
}
.person-item{
    border: 1px solid #eaebf1;
    padding: 14px 10px 10px 10px;
    cursor: pointer;
    background: #f8f9ff;
    height: 71px;
}
.person-list-grid{
    border-radius: 4px;
    margin-right: 1% !important;
}
.person-item:hover {
    background: #f0f9f1;
}
.person-initial{
    color: #c4c4d6;
    font-size: 16px !important;
    font-weight: 700;
}


@media only screen and (max-width: 667px) {
 .personListSection{ padding: 20px 20px 20px 20px;}    
 .personListSection .row.contact-list .row.person-list-grid{ margin-left: 0px;}
 .personListSection .contact-col-1 {max-width: 100%; flex: 0 0 100%; text-align: left;}
 .personListSection .contact-col-2 {max-width: 100%; flex: 0 0 100%; padding-right: 0; }
}