
.salesnews-heading{
    padding-left:4%!important;
    }
    .sales-new-title{
    font-size: 30px !important;
    font-family:'Open Sans',sans-serif;
}

.salesContanerGrid{
    padding: 0px 15px 0px 15px !important;
}

.tab-btn-container{
    margin-right: 6px !important;
    position: relative;
    float: right;
}


.btnText{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: right;
    text-transform: none !important; 
}
.salesNewsHeading{
    margin-left: 15px !important;
    margin-top: 20px !important;
}
.salesNewsTitile{
    margin: 0px;
    font-size: 30px;
    font-weight: bold;
    color: #232f44;
  text-align: left;
}
.breadcrumbsAlign{
    margin-bottom: 11px !important; 
    display:block;
    


}
.btnText:hover{
    cursor: pointer;
    border:none;
    text-decoration: underline !important;
    background-color: Transparent !important;
}

.activeBtn{
    color: #19d98c!important;
}


/* manage newa */

.separator{
    margin: 0px 10px 0px 10px;
    color: gray;
}
.containerBtn{
    padding-right: 12px;
    margin: 0px !important;
}

.breadcrumbsWrap{

    display: inline-block;
}
.breadcrumbsAlignItem{
    margin-left: 15px !important;
}

.rightContentInner .grid-icon-container img {
    margin-right: 21px;
}

@media (min-width: 992px) {
    
    .rightContentWrapper{
        order:3;
        position: fixed !important;
        width: 28.7% !important;
        right:0 !important;
        top:68px !important;
        padding:10px !important;
        height: calc(100% - 68px) !important;
        box-shadow: -1px -3px 5px #b6b6b8 !important;
        overflow-y:scroll !important; 
        overflow-x:hidden !important; 
    }
    .salesContanerGrid{
        order:2; 
        padding: 0px 15px 0px 15px !important;
    }
}
@media (max-width: 992px) {
    .rightContentWrapper{
        padding: 10px 10px 0px 10px!important;
        order:3;
        position: absolute !important;
        width: 100% !important;
        right:0 !important;
        top: 195px !important;
        padding:10px !important;
        box-shadow: -1px -3px 5px #b6b6b8 !important;
        overflow-x:hidden !important; 
    }
    .salesNewsMobile{
        order: 4;
        position: relative;
        top: 175px;
    }
    .salesContanerGrid{
        order:2;  
        padding: 0px 15px 0px 15px !important;
    }
   
}
@media (max-width: 992px) {
    .rightContentWrapper{
        padding: 10px 10px 0px 10px!important;
        background: white;
        order: 2;
        height: auto !important;
        width: 100% !important;
        position: absolute !important;
        right:0 !important;
        top: 195px !important;
        padding:10px !important;
        box-shadow: -1px -3px 5px #b6b6b8 !important;
        overflow-x:hidden !important; 
    }
    .salesNewsMobile{
        order: 4;
        position: relative;
        top: 175px;
    }
   
    
}