.reset_password_popup {
    text-align: center;
    width: 630px;
    height: 337px;
    border-radius: 5px;
    background-color: #ffffff;
    margin: 15% auto;
}

.tick {
    display: flex;
    justify-content: center;
    height: 170px;
    align-items: center;

    .logo {
        width: 66px;
        height: 66px;
        background-color: #ffffff;
        border: 5px solid #17d98b;
        border-radius: 50%;
        color: #17d98b;
        padding: 10px;
    }

    .checkmark {
        display: inline-block;

        &:after {
            /*Add another block-level blank space*/
            content: '';
            display: block;

            /*Make it a small rectangle so the border will create an L-shape*/
            width: 15px;
            height: 30px;

            /*Add a white border on the bottom and left, creating that 'L' */
            border: solid #17d98b;
            border-width: 0 5px 5px 0;

            /*Rotate the L 45 degrees to turn it into a checkmark*/
            transform: rotate(45deg);
        }
    }
}

.message {
    font-weight: 500;
    padding: 10px 20px 54px;

    h2 {
        font-size: 24px;
        color: #000000;
        margin: 0px;
    }
}

.okButton {
    button {
        width: 180px;
        height: 50px;
        object-fit: contain;
        border-radius: 3px;
        background-color: #17d98b;
        font-size: 16px;
        color: #ffffff;
    }

    button:hover {
        background-color: #17d98b;
    }
}
.btn{
    text-transform: none!important;
}

@media(max-width:630px) {
    .reset_password_popup {
        width: 90%;
        margin: 30% auto;
    }

    .tick {
        height: 150px;
    }
} 