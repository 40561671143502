.popover_content {
    // width: 576px;
    width: 700px;
    padding: 20px 20px 0 20px;
    overflow-x: hidden;
}

.pipeline_border {
    border-top: 1px solid #eaebf1;
    margin-top: 20px;
    padding-top: 20px; 
}
.handIcon {
    text-align: right;
}

.user_name, .lead_name, .company_name {
    max-width: 100%;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.assigned_to {
    display: inline-block;
    max-width: 250px;
    vertical-align: top;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
    margin-left: 5px;
}

.popover_content_title {
    display: inline-block;
    vertical-align: top;
}


/*** media query **/
@media only screen and (max-width: 767px) {
.handIcon{text-align: left; margin-top: 20px; }
}

@media only screen and (max-width: 533px) {
    .popover_content { width: 100%;}
}

@media only screen and (max-width: 400px) {
.user_name, .lead_name, .company_name{max-width: 100%; }
}

/*** media query **/