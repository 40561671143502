@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700');
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem !important;
}
span, p, li, div, b{
  font-family: 'Open Sans', sans-serif !important;
  font-size: 0.875rem !important;
}
code {
  font-family: 'Open Sans', sans-serif;
}
/* .react-datepicker-wrapper, .react-datepicker__input-container{
  width:100%;
} */
