.main_wrapper {
    padding: 20px 30px;
}

.activities_container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: #1c2535;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    hr {
        margin: 0;
    }
}

.activities_container_header {
    width: 100%;
    height: 54px;
    background-color: #232f44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.activities_container_heading {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.activities_container_actions {
    display: flex;
    justify-content: center;
    position: relative;
}

.calendar_container {
    display: flex;
    justify-content: flex-end;

    .buttons {
        width: 66px;
        background-color: #ffffff;
        border: solid 2px #eaebf1;
        color: #9c9bb2;
        border-radius: 3px;
        margin-left: 10px;
        height: 46px;
    }

    .selected {
        border: solid 2px #232f44;
        color: #232f44;
    }

    .date_picker_icon {
        background: url(../../../../../src/assets/images/content-images/calender.png) 0 0 no-repeat;
        width: 22px;
        height: 21px;
        display: inline-block;
        // position: absolute;
        // right: 35px;
        // top: 38px;
        pointer-events: none;
    }
}

.centerAlign {
    align-items: center !important;
}
@media only screen and (max-width: 480px) {
.calendar_container{ margin-top: 20px;}
}