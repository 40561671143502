.snack-success > div{
    z-index: 333333599;
    background-color: #43a047;
}
.snack-error > div{
    z-index: 333333599;

    background-color: #cc0000;
}
.snack-info > div{
    z-index: 333333599;

    background-color: #1976d2;
}
.snack-warning > div{
    z-index: 333333599;

    background-color: #ffa000;
}

.snack-success > div > div:first-child ,.snack-error > div > div:first-child ,.snack-warning > div > div:first-child ,.snack-info > div > div:first-child  {
    word-wrap: break-word;
    width:270px;
    overflow: hidden;
    height: auto;
    white-space: normal !important;
    padding-right: 20px;
}

.snack-success > div > div:nth-child(2) ,.snack-error > div > div:nth-child(2) ,.snack-warning > div > div:nth-child(2) ,.snack-info > div > div:nth-child(2)  {
    right: 0;
    bottom: 0;
    position: absolute;
    padding-right: 8px;
    font-size: 28px;

}


    