.activities_container_body {
    overflow-y: auto;
    height: 410px;
    width: 100%;
    margin-bottom: 30px;
}

.activities {
    width: 100%;
    padding: 10px;
}

.activity {
    width: 100%;
    border-radius: 5px;
    min-height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tasks {
    border-left: 5px solid #232f44;
    width: 100%;
    border-radius: 5px;
    background-color: #eeeff0;
    margin-bottom: 10px;

    .edit_delete {
        height: 36px !important;
    }

    .task {
        padding: 5px 0px 5px 20px;
        min-height: 36px;
        display: flex;
        align-items: center;
    }

    .tasks_with_lead {
        padding: 10px 0px 0px 0px;
        .lead_name {
            margin: 0 0 0px 20px;
        }
    }
}

.meeting {
    background-color: #fbe9ec;
    border-left: 5px solid #e1667f;
}

.email {
    background-color: #e5f0fe;
    border-left: 5px solid #4c97f7;
}

.phone {
    background-color: #fbf4d9;
    border-left: 5px solid #ffd941;
}

.other {
    background-color: #e6f6e7;
    border-left: 5px solid #19d98c;
}

.timing {
    margin: 0 20px;
    color: #232f44;
    font-size: 16px;
    font-weight: 600;
}

.sales_activity {
    width: 100%;
    padding: 10px 0px 10px 10px;
    display: flex;
    align-items: center;

    ul {
        padding: 0px !important;
    }
}

.sales_activity_header {
    display: inline;
    color: #232f44;
    font-size: 14px;
    font-weight: bold;
    margin: 0 4px 4px 0;
}

.sales_activity_lead_title {
    display: inline;
    color: #232f44;
    font-size: 12px;
    font-style: italic;
    margin: 0 4px 4px 0;
}

.contact_detail {
    display: flex;
    width: 100%;

    .contact {
        display: inline-block;
        min-width: 50%;
        padding-left: 20px;
        position: relative;
        margin-right: 5px;

        .contact_name {
            width: 130px;
        }
    }

    img {
        width: 14px;
        height: 14px;
        margin-right: 9px;
        position: absolute;
        left: 0;
        top: 1px;
    }

    h6 {
        // display: inline;
        // font-size: 12px;
        // color: #1c2535;
        display: block;
    font-size: 12px;
    color: #1c2535;
    width: 70px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
     
    }
}

.date_container {
    padding: 10px 10px 0 10px;

    .date {
        margin: 0;
        font-size: 14px;
        color: #232f44;
    }
}

.dots {
    position: relative;
    background-color: #b2b2b3;
    border-radius: 5px;
    font-size: 0;
    margin-right: 2px;
    padding: 2px;
}

.buttons_edit_delete {
    min-width: 114px;

    .normal {
        width: 10%;
    }

    .hoverable {
        display: none;
    }
}

.buttons_edit_delete:hover {
    .normal {
        display: none;
    }

    .hoverable {
        display: inline;
    }
}

.edit_delete {
    width: 56px;
    height: 56px;
    background-color: #232f44;
    display: inline-block;

    .edit_icon {
        background: url('../../../assets/edit_icon.png') 0 0 no-repeat;
        width: 18px;
        height: 18px;
        object-fit: contain;
        background-color: #ffffff;
    }

    .delete_icon {
        background: url('../../../assets/delete_icon.png') 0 0 no-repeat;
    }
}

.deleteicon {
    background: url('../../../assets/delete_icon.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 14px;
    margin-right: 10px;
}

.editicon {
    background: url('../../../assets/edit_icon.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.menuitem {
    display: flex;
    align-items: center;
}



@media only screen and (max-width: 480px) {
.contact_detail { display: block;}
.sales_activity_lead_title { display: block;}
}
