.containerWrap{
    padding:2%
}

.heading{
    padding-left: 1.2%;

    font-size: 30px;
    font-weight: bold;
  color: #232f44;
}
.breadcrumbsWrap{
    padding-left: 1.2%;
}
.separator{
    margin-top: 5px;
    color: gray;
}
.containerBtn{
    padding-right: 1%;
    
}