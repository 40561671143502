.login {
    width: 400px;
    margin: auto;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;

    .logo_container {
        padding: 30px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 17px;

        .logo {
            width: 210px;
            height: 80px;
        }
    }

    .welcome {
        width: 100%;
        margin-bottom: 56px;

        .heading {
            font-size: 36px;
            font-weight: 300;
            margin: 0 0 10px 0;
            display: inline-block;
        }

        .heading_leadx {
            font-size: 36px;
            font-weight: 300;
            margin: 0 0 10px 0;
            font-weight: bold;
            display: inline-block;
            margin-left: 10px;
        }

        h5 {
            font-size: 16px;
            text-align: center;
        }
    }

    .login_form {
        width: 100%;
        margin-bottom: 40px;

        .input_container {
            margin-bottom: 30px;
            width: 100%;
            max-width: 100%;
        }

        .input {
            height: 60px;
            width: 100%;
            padding: 20px 29px;
            margin: auto;
            background-color: #232f44;
            color: #ffffff;
            border-radius: 3px;
            border: solid 2px #4d5b73;
            font-size: 16px !important;
            caret-color: #ffffff;
            input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0px 1000px #232f44 inset;
                box-shadow: 0 0 0px 1000px #232f44 inset;
                -webkit-text-fill-color: #ffffff;
            }
        }
        .input::after {
            border-bottom: none;
        }

        ::placeholder {
            color: #a1abbb;
            opacity: 1;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #a1abbb;
        }

        .login_button_container {
            display: flex;
            justify-content: center;
            height: 50px;

            .button {
                height: 100%;
                width: 107px;
                background-color: #17d98b;
                color: #ffffff;
                padding: 13px 34px;
                cursor: pointer;
                border: none;
                font-size: 16px;

                span {
                    text-transform: none;
                    font-size: 16px !important;
                    line-height: normal;
                }
            }

            .button:disabled {
                background-color: grey;
                cursor: not-allowed;
            }
        }
    }

    .forgot_password {
        margin-bottom: 30px;

        h5 {
            font-size: 16px;

            a {
                color: #ffffff !important;
                text-decoration-line: underline;
            }

            a:hover,
            a:active {
                color: #17d98b !important;
            }
        }
    }
}

.errors {
    color:#cc0000;
    display: block;
    text-align: left;
}

@media(max-width:767px) {
    .login {
        width: auto;
        max-width: 400px;
    }
}

@media only screen and (max-width: 390px) {
.login{max-width: 94%;}   

}