/* .previewNewsDilogContainer{
    height: 455px;
    width: 598px;
} */

.DialogContent{
    padding: 3% !important;
}
.titleText{
    font-size:23px;
    text-align: center;
    padding: 18px 0px 0px 0px !important;
}
.imgPreview{
    width: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    max-width: 100%;
    /* width: 100%; */
    height: 290px;

}

.imgPreviewWrap{
    background-color: #f9f9f9;

}

.userAvatarIcon{
    display: inline !important;
    width: 36px;
    height: 36px;
    margin: 10px 10px 10px 20px;

   

}
.newTitle{
    margin: -2px 0px 18px 0px !important;

}
.userName{
    display: inline !important;
}
.closeBtn{
    top: -10px;
    float: right;   
    right: 8px;
}

/* 
.MuiDialog-paperWidthSm-82{
    max-width: 751px !important;
}    */

.divider-align{
    margin: 0px 0px 0px 0px !important
}

.news-text{
    padding: 1%
}

.newsDiscriptionText{
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.dialogContainer > div > div{
    width: 751px;
    height: 100%;
} 

.dialogContainer .closeBtn{
    padding-bottom: 6px; 
}


@media (max-width: 480px) {
    .imgPreview{
        height: auto !important;
    }
}
@media (max-width: 767px) {
    .imgPreview{
        height: auto !important;
    }
}

