@import './app/common/variables.scss';

body {
  overflow-x: hidden;
  background-color: #f9f9fc !important;
}
.row.mar_left{
  margin-left: 0px;
}
.row.mar_right{
  margin-right: 0px ;
}
.padding_left{
  padding-left: 0px !important;
}
.padding_left input{
  border-left: none;
}
.padding_right{
  padding-right: 0px !important;
}
.p_position{
  position: relative;
}
.add_cursor_pointer{
  cursor: pointer !important;
}
.add_cursor_pointer input.rc-time-picker-input{
  cursor: pointer !important;
}
button:focus,
a:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

.App {
  text-align: center;
}

.heading {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: bold;
  color: $text-color-black;
}

.breadcrumbs-container span, .breadcrumbs-container a  {
  color: #636d7e !important;
}

.container-body {
  margin-top: 25px;
}

.MuiPrivateTabIndicator-root-249 {
  display: none;
}

.btm-space {
  margin-bottom: 10px;
}

div.main-wrapper {
  padding: 20px 30px 0;
}

.date-picker-icon {
  background: url(../src/assets/images/content-images/calender.png) 0 0 no-repeat;
  width: 22px;
  height: 21px;
  display: inline-block;
  position: absolute;
  right: 35px;
  top: 38px;
  pointer-events: none;
  //cursor: pointer;
}

.time-picker-icon {
  background: url(../src/assets/images/content-images/timer.png) 0 0 no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 38px;
  pointer-events: none;
  cursor: pointer;
}


/** green scroll ***/
::-webkit-scrollbar {width: 4px; height: 4px; }
::-webkit-scrollbar-button { width: 16px; height: 16px;}
::-webkit-scrollbar-thumb { background: #19d98c; border: 0px none #ffffff; border-radius: 50px;}
::-webkit-scrollbar-thumb:hover {background: #ffffff;}
::-webkit-scrollbar-thumb:active { background: #000000;}
::-webkit-scrollbar-track { background: #666666; border: 0px none #ffffff; border-radius: 48px;}
::-webkit-scrollbar-track:hover { background: #666666;}
::-webkit-scrollbar-track:active { background: #333333;}
::-webkit-scrollbar-corner { background: transparent;}
/** moz**/

// .ongoing-sales-body{
//   overflow-y: scroll;
//   scrollbar-color: #19d98c #666666;
//   scrollbar-width: thin;
//   border:0px;

// }
/** green scroll ***/





.responsive-aside-left {
  margin-left: 198px;
  margin-top: 70px;
}

.main_wrapper {
  padding: 20px 30px;
}

header.MuiPaper-elevation4-31{
  box-shadow: 0px 0 4px #000;
}

.overflow_x_hidden{
  overflow-x: hidden;
}

@media(max-width:600px) {
  .heading {
    text-transform: uppercase;
    font-size: 20px;
  }

  div.main-wrapper {
    padding: 20px 15px 0;
  }

  .AsideLeft-root-1+div {
    margin-left: 0 !important;
    margin-top: 56px !important
  }
}

@media(max-width:598px) {
  .responsive-aside-left {
    margin-top: 70px;
    margin-left: 0px;
  }
}

.react-date-picker__calendar--open {
  margin-bottom: -242px !important;
}

.react-daterange-picker__calendar--open {
  top: 45px !important;
  left: -110px !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup,
.react-date-picker__inputGroup input,
.react-date-picker__inputGroup span {
  display: none;
}

.react-daterange-picker__wrapper {
  border: none !important;
  font-size: 0 !important;
}

.react-daterange-picker__inputGroup input,
.react-daterange-picker__inputGroup span,
.react-daterange-picker__wrapper,
.react-daterange-picker__wrapper .react-daterange-picker__range-divider {
  display: none;
}

.react-daterange-picker__calendar {
  z-index: 3 !important;
}

.react-calendar__tile--active {
  background: #19d98c !important;
}

.react-calendar__tile--hasActive {
  background: #d4eed7 !important;
}

.rc-time-picker {
  display: block !important;
  height: 46px !important;
  border: solid 1px #c0c0c0 !important;
  margin-bottom: 8px;
  margin-top: 5px !important;
  width: 100% !important;
  padding: 5px !important;
  border-radius: 3px !important;
  cursor: pointer !important;
}

.rc-time-picker-input {
  font: inherit !important;
  color: #000000 !important;
  border: none !important;
  padding: 0 !important;
 
}

.rc-time-picker-panel {
  z-index: 1301 !important;
}

.rc-time-picker-clear {
  display: none;
}

.breadcrumbs_container {
  color: #636d7e;
  a, a:hover, a:visited {
      color: #636d7e !important;
  }
}
.notesHint{
  float: right;
  font-size: 12px !important;
  color: grey;
}
.httpPrefix{
  display: inline-block;
  padding: 13px 0px 11px 10px;
  border: solid 1px #c0c0c0;
  border-right: none;
}

.wrap_prev_next{
  display: flex;
  justify-content: space-between;
}
.wrap_prev_next .div_prev{
  margin-left: 15px;
}
.wrap_prev_next .div_next{
  margin-right: 15px;
}
.profile_wrap{
  margin: 0px auto;
  padding: 0px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  position: relative;
}

/*****/
.dialog-title{
  // padding-top: 13px;
  padding-top: 0px;
}
.dialog-btn-bottom{
  // padding-bottom: 0px 0px 25px 0px !important;
  padding-bottom: 0px !important;
}
.dialog-btn-bottom.dialog-btn-bottom-adding{
  padding-bottom: 9px !important;
}
.contact-popup-top{
  margin-top: 0px !important;
}
.dialog-content-title-left{
  margin-left: 1px !important;
}
.title_top_padding{
  padding-top: 13px;
}
.title_bottom_padding{
  padding-bottom: 19px !important;
}
.leadDeatails_popup_wrapper{
  width: 1209px;
}
.activity_popup_addNew{
  width: 1112px;
}
.newContact-wrap{
  width: 1280px;
}
.pipeline_dots{
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pipeline_dots_1{
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn_mobile_view_profie{
  display: none;
}
.btn_mobile_desktop_hide{
  display: none;
}
.react-calendar{
   margin-bottom: 30px;
}
 
.sales-item-padding-top {
  padding-top: 10px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.right_bottom_section_margin .grid-card.right-grid-card{
  margin-bottom: 0px;
}
.right_bottom_section_margin {
  margin-bottom: 10px;
} 
// .lead_dialog_content_remove_overflow-y{
//   overflow-y: hidden !important; 
// }

.lead_dialog_content_remove_overflow-y{
  overflow-y: hidden !important;
}
.remove_scrollY_pop_date .react-date-picker{
  position: initial !important;
}
.react-date-picker__calendar .react-calendar{
  margin-bottom: 30px;
}
.item-status.item-status-right-spacing{ padding-right: 52px;}
.sales-pop-header-space{
  padding: 18px 0px 18px 0px !important;
}
  

/*** media query start here **/
@media(max-width:1366px) {
  .newContact-wrap{
    max-width: 100%;  
    // width: 100%;  
  }
  .item-status.item-status-right-spacing{ padding-right: 30px;}
}

@media screen and (max-width: 959px) and (min-width: 600px) {
  .AsideLeft-drawer-9{ 
    display: none;
    width: 138px;
  }
  .AsideLeft-drawerPaper-13{ display: none; overflow-y: hidden;}
  .aside-left_brand_logo_mobile, .aside-left_brand_logo_mobile{ display: inline-block;}
  .aside-left_brand_logo_mobile{ display: block;}
}
@media screen and (max-width: 959px) and (min-width: 768px) {
  nav{ display: none !important;}
  .AsideLeft-drawer-9 .aside-left_brand_logo_mobile{ display: block;}
  .tab-logo > div {
    width: 138px;
    display: block;
  }
  .tab-logo button{
    position: absolute;
    left: 150px;
    top: 14px;
  }
}

@media(max-width:1024px) {
  .leadDeatails_popup_wrapper { width: 100%;}
  .activity_popup_addNew{ width: 100%;}

  .row.mar_left{margin-left: -30px;}

  .react-daterange-picker__calendar--open {
    top: 50px !important;
    left: -130px !important;
  }
  
}



@media(max-width:769px) {
  .responsive-aside-left {
    margin-top: 70px;
    margin-left: 0px;
  }
  // .padd_top_remove{
  // padding-top: 0px !important;  
  // padding-bottom: 0px !important;
  // }
}
@media(max-width:768px) {
  .react-daterange-picker__calendar--open {
    top: 50px !important;
    left: -130px !important;
  }
}

@media(max-width:767px) {
.padding_right_remove{
  padding-right: 15px !important;
}
  .profile_bottom_space{ margin-bottom: 30px;}

  .btn_mobile_view_profie{
    display: inline-block;
    vertical-align: top;
    background:#3c4a5f url(../src/assets/images/header-icon/create-contact.png) no-repeat center center;
    width: 38px;
    height: 33px;
    margin-left: 10px;
    border-radius: 4px;
    margin-right: -21px;
   
  }
  .btn_mobile_desktop_hide{
    display: inline-block;
    vertical-align: top;
    background: #3a4860;
    text-align: center;
    cursor: pointer;
    width: 63px;
    max-width: 63px;
    color: #fff;
    border-radius: 0px;
    border: 0px;
    height: 68px;
    margin: 0;
    padding: 0;
    position: relative;
    top: -18px;
    left: 31px;

  }

  .react-daterange-picker__calendar--open { top: 50px !important; left: -103px !important;  }
  .width > div > div { margin: 48px 20px;}
  .contactForm > div > div { margin: 48px 20px;}
  .width h6{ height: 40px; line-height: 40px;}
  .pipe-linemain-div > div > div { margin: 48px 20px !important;}

  .padding_left input{ border-left: solid 1px #c0c0c0;}
  .padding_left_remove_add{ padding-left: 15px !important;}

  .add_activity_mobile_left_right{ padding-left: 15px !important; padding-right: 15px !important;}
  
}
  

  
@media(max-width:600px) {
  .heading {
    text-transform: uppercase;
    font-size: 20px;
  }

  div.main-wrapper {
    padding: 20px 15px 0;
  }

  .AsideLeft-root-1+div {
    margin-left: 0 !important;
    margin-top: 56px !important
  }
  .react-daterange-picker__calendar--open { left: -64px !important;}
}
@media(max-width:569px) {
  .react-daterange-picker__calendar--open { left: -33px !important;}
  
}
@media(max-width:533px) {
  .react-daterange-picker__calendar--open { left: 0px !important;}
}

@media only screen and (max-width: 480px) {
.row.wrap_prev_next{ display: block;}  
.wrap_prev_next .div_prev{    margin-right: 15px;}
.row.wrap_prev_next .button_prev{ display: block; width: 100%;}  
.wrap_prev_next .div_next{ margin-left: 15px; margin-right: 15px; margin-top: 10px; }
.row.wrap_prev_next .div_next .button_next{ display: block; width: 100%; float: inherit; } 

.react-daterange-picker__calendar{width: 300px !important; max-width: 200vw !important;}
.react-daterange-picker__calendar--open { /*left: 136px !important;*/}
}

@media only screen and (max-width: 424px) {
.btn_mobile_desktop_hide{top: -56px;}  
.btn_mobile_view_profie{ margin-right: 32px; position: relative; right: 10px;}
.react-daterange-picker__calendar--open { left: 53px !important;}
}
@media only screen and (max-width: 414px) {
  .btn_mobile_desktop_hide {top: -56px;}
  .btn_mobile_view_profie { margin-right: 0;  right: 40px;}
  .react-daterange-picker__calendar--open { left: 63px !important;}
  
}
@media only screen and (max-width: 400px) {
  .btn_mobile_desktop_hide {top: -56px;}
  .btn_mobile_view_profie { margin-right: 0; position: relative; right: 40px;  }
  .react-daterange-picker__calendar--open { left: 77px !important;}

}
@media only screen and (max-width: 390px) {
  .react-daterange-picker__calendar--open { left: 87px !important;}
}
@media only screen and (max-width: 375px) {
  .react-daterange-picker__calendar--open { left: 102px !important;} 
}
@media only screen and (max-width: 360px) {
  .react-daterange-picker__calendar--open { left: 117px !important;}  
}
@media only screen and (max-width: 320px) {
  .react-daterange-picker__calendar--open {/*left: 138px !important;*/ left: -18px !important;}
  
}

// input {
//   margin-bottom: 8px !important;
// }


/*** media query start here **/

.react-date-picker{
  display: block !important;
  top: 27px;
}
.react-date-picker__calendar{
  top: -27px !important;
}

.pstatic{
// position: initial !important;
}