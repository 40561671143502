.input{
    width: 100%;
    padding: 12px 10px 12px 10px;
    margin-top: 2%;
    border: 1px solid #c0c0c0;
    border-radius: 3px;
    // margin-bottom: 8px;
    margin-bottom: 0px;
}
.suggestionsContainer {
    position: absolute;
    width: 88%;
    z-index: 10000;
    background: #ffffff;
    cursor: pointer;
    // max-height: 200%;
    max-height: 115px;
    overflow-y: auto;
}
.suggestionsContainer ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
}
.suggestionsList{
    margin-bottom: 0px;
    margin-block-start: 0px;
    padding-inline-start: 0px;
    overflow: hidden;
    border: 1px solid #c0c0c0;
    box-shadow: 5px 8px 8px 8px #d3d3d31f;
}
.suggestion{
    padding: 10px 10px;
}
.suggestionHighlighted{
    background: lightgray;
}

.autocomplete_input input{
    padding: 12px 10px 12px 10px;
    margin-bottom: 8px; 
    
}
// .suggestionsContainer ul li:last-child{
//     padding-bottom: 30px;
// }
.suggestionsContainer ul{
   // padding-bottom: 50px;
}