.add_activity {
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    outline: none;
}

.add_activity_dialog_title {
    font-size: 22px !important;
    font-weight: 600;
    text-align: center;
    margin: 5px 0px;
    // margin: 5px 0 4px 0px;
}

.required_field {
    color: red;
}

.form_group {
    margin-bottom: 15px;

    input:focus {
        outline: none;
    }
}
.form_group input::placeholder {
    color: #9c9bb2 !important;
    font-size: 13px !important;
}

.form_title {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    color: #232f44 !important;
}

.inputStyle {
    height: 46px;
    border: solid 1px #c0c0c0;
    margin-bottom: 8px;
    margin-top: 5px !important;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
 
}

.inputStyle:focus,
.selectCompany:focus,
.textArea:focus {
    outline: none;
}

.selectCompany {
    cursor: pointer;
    height: 46px;
    margin-top: 5px !important;
    border: solid 1px #c0c0c0;
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 3px;
    -webkit-appearance: none;
    background: url(../../../../assets/images/content-images/select-arrow.png) 98% center no-repeat;
}

.saveButton {
    width: 160px;
    height: 46px;
    border-radius: 3px;
    color: white !important;
    background-color: #0b0e22 !important;
    margin: 13px 0 0 0 !important;
    text-transform: capitalize !important;
    cursor: pointer;
}

.datepicker {
    position: relative;
}

.textArea {
    resize: none;
    height: 46px;
    width: 100% !important;
    margin-right: 0px !important;
    border: solid 1px #c0c0c0;
    border-radius: 3px;
    padding: 5px;
    //margin-bottom: 8px;
    margin-bottom: -8px;
    margin-top: 5px !important;
}

.tabsPadding {
    // margin-bottom: 22px;
    height: 46px;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    span {
        background-color: inherit;
    }
}

.tabsDesign {
    width: 180px !important;
    color: black !important;
    margin: 0px 5px !important;
    border: 2px solid black !important;
    border-radius: 3px !important;
    height: 46px !important;
    min-height: 46px !important;
    // text-transform: capitalize !important;
  text-transform: none !important;
    padding: 0px !important;
}

.tabNotselected {
    border: 2px solid #eaebf1 !important;
    border-radius: 3px !important;
    margin: 0px 5px !important;
    padding: 0px !important;
    text-transform: none !important;
}

.errorMessage {
    font-size: 12px !important;
    color: #cc0000;
}

input:focus {
    outline: none;
}

.optional {
    color: #9c9bb2;
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
}

.helperText {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #9c9bb2;
    font-size: 10px !important;
    margin: 7px 0px 0px 0px !important;
    line-height: 14px !important;
}

.submit_loader {
    position: relative;
    width: 85px;
    height: 50px;
}
.tabsContainerPadding{
    position: relative;
}