$all-color: #232f44;
$new-color: #232f44;
$lead-color: #285046;
$opportunity-color: #30774a;
$proposal-color: #39a14c;
$negotiation-color: #52b660;
$closed-color: #69d578;
$parked-color: #91F79F;

$white-color: #ffffff;
$text-color-black: #232f44; 