.profile_card{
    // height: 465px;
    height: 500px;
}

.crop_image_title {
  font-size: 22px !important;
  font-weight: 600;
  text-align: center;
  margin: 5px 0px;
}

.cropButton {
  width: 160px;
  height: 46px;
  border-radius: 3px;
  color: white !important;
  background-color: #0b0e22 !important;
  margin: 9px 0 0 0 !important;
  text-transform: capitalize !important;
}

.cropper {
  width: 100%;
  margin-bottom: 20px;
}

.popup_dialogbox > div > div {
  width: 640px;
}

.bigAvatar{
    width: 170px !important;
    height: 170px !important;
    // margin-left: 51px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
.user_avatar_section{
    padding: 15px 30px 15px;
    text-align: center;
    margin-bottom: 0px !important;
}
.user_name{
    font-size: 24px !important;
    font-weight: 600;
}
.user_role{
    font-size: 14px;
}
.bold_text{
    font-weight: 600;
}
.contact_info{
    font-size: 14px !important;
    font-weight: 700;
}
.contact_values{
    // padding: 2% 0% 2% 0%;
}
.email{
    padding: 6% 0% 6% 0%;
}
.email_icon, .phone_icon{
    margin-right: 5%;
}
.card_body{
    padding: 30px;
}
.card_body_1{
    padding: 10px 30px;
}
.main_label{
    font-size: 14px !important;
    font-weight: 700;
    margin-bottom: 20px;
}
.inputStyle{
    height: 46px;
    border: solid 1px #c0c0c0;
    margin-bottom: 8px;
    margin-top: 5px !important;
    width:100%;
    padding: 5px;
    border-radius: 3px;
  }
  .label{
    font-size: 14px!important;
    text-align: left;
    color: #232f44;
  }
  .selectTag{
    height: 46px;
    margin-top: 5px !important;
    border: solid 1px #c0c0c0;
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 3px;
    -webkit-appearance: none;
    background: url(../../../assets/images/content-images/select-arrow.png) 95% center no-repeat;
  }
  .contact_info_section{
      padding-top: 10px;
  }
  .personal_info_section{
      padding-bottom: 15px;
  }
  .edit_icon_section{
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .edit_icon{
      font-size: 16px;
  }
  .image_delete_section, .image_edit_section{
    position: absolute;
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    width: 41px;
    height: 41px;
    padding: 0;
    line-height: 41px;
    text-align: center;
    bottom: 25px;
  }
  .image_delete_section {
    background: #232f44;
    right: -10px;
  }
  .image_edit_section {
    z-index: 1;
    background: #19d98c;
    left: -10px;
  }
  .persnoal_info_label, .persnoal_info_value{
    font-size: 14px !important;
  }
  .persnoal_info_value{
      color: #8c8b8b;
  }
  .personal_info_firstRow, .personal_info_secondRow{
      margin-bottom: 10px !important;
  }
  .update_btn{
    padding: 10px 50px;
    background-color: #232f44;
    color: #ffffff;
    font-size: 14px !important;
    border: none;
    border-radius: 3px;
    margin-top: 15px;
  }
  .required_field , .errorMessage, .status_inactive{
    font-size: 12px;
    color:#cc0000;
}
.status_active{
    color: #19d98c;
}
.overflow_text{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.form_group input::placeholder {
    color: #9c9bb2 !important;
    font-size: 13px !important;
  }
 

  .profile_button_edit_remove{
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    text-align: center;
  }  
  .profile_button_edit_remove .btn_edit{
    margin: 10px;
    padding: 0px;
    display: inline-block;
    border: 0px;
    border: 0px;    
    text-align: center;
    background: transparent;
    color: #19d98c;
    font-size: 13px;
    text-decoration: underline;
  }  
  .profile_button_edit_remove .btn_remove{
    margin: 10px;
    padding: 0px;
    display: inline-block;
    border: 0px;
    border: 0px;    
    text-align: center;
    background: transparent;
    color: salmon;
    font-size: 13px;
    text-decoration: underline;
  }

/******/
@media(max-width:1024px) {
.card_body_1{ padding: 10px 20px;}
}
@media(max-width:767px) {
.profile_card{ height: 100%;}
.profile_bottom_space{ margin-bottom: 30px;}
.label{ margin-bottom: 0px !important;}
.inputStyle{ margin-bottom: 12px !important;}
}



